.level_label{
    width: 100% !important;
    margin-bottom: 20px;
}
.login-btn-style{
    float: left;
    margin-top: 15px;
    margin-bottom: 25px;
}
.dob_class{
    display: inline-flex;
    margin-top: 10px;
}