.ca-rb-footer .newthemefooterbox{background:#1d2936;padding-top:56px !important;}
.newthemefooterbox{background:#1b1b25;clear:both;float:left;padding-bottom:20px;padding-left:45px;width:100%;}
.ca-rb-footer .newthemefooternode1{width:33%;float:left;padding-left:5px;}
.ca-rb-footer .newthemefooternode2{width:33%;padding-top:18px !important;float:left;padding-left:5px;}
.ca-rb-footer .newthemefooternode4{width:34%;float:left;padding-left:5px;}
.ca-rb-footer h5 a{color:#3498db !important;font-family:"montserratregular",sans-serif;font-size:16px;font-weight:normal;text-decoration:none;text-transform:uppercase;letter-spacing:1px;}
.newthemeleftbox h5{margin:0px;letter-spacing:1px;line-height:20px;}
ul.nav.menu_footer{line-height:1.4;clear:both;margin-top:26px;float:left;clear:both;width:100%;padding-left:0px;}
ul.nav.menu_footer li{display:inline-block;list-style:none;line-height:1.65;padding-bottom:5px;width:100%;}
.ca-rb-footer ul.nav.menu_footer li a, ul.nav.menu_footer li a{font-family:"montserratlight",sans-serif !important;font-size:13px !important;font-weight:normal !important;text-decoration:none;color:#fff;text-transform:capitalize;line-height:25px;letter-spacing:1px;}
.newthemeleftbox{width:983px;float:left;clear:both;}
.ca-rb-footer .newthemerightbox h5{margin-bottom:28px;}
.ca-rb-footer .newthemerightbox p a{color:#3498db !important;text-decoration:none;font-family:"montserratlight",sans-serif;}
.ca-rb-footer .newthemerightbox p{color:#fff;font-family:"montserratlight",sans-serif;font-size:13px !important;line-height:2 !important;letter-spacing:1px;}
.address span{display:block;color:#fff;margin-bottom:15;line-height:1.4;font-family:"montserratlight",sans-serif;font-size:13px !important;line-height:2 !important;}
.rb-sec-outerbox ul.pagenavigation{padding-top:4px;margin-bottom:19px;margin-top:0px;}
ul.pagenavigation{clear:both;line-height:1;margin-bottom:29px;padding:0;padding-top:0px;}
ol, ul{list-style:none;}
hr.blue-separator{border:3px solid #3498db;display:inline-block;height:0;margin-bottom:0;margin-top:13px;width:70px;margin-bottom:28px;}
ul.nav.menu_city{margin-top:9px;padding:0;}
ul.nav.menu_city li{display:inline;list-style:none;line-height:1;padding-bottom:5px;}
.newthemerightbox{width:304px;float:left;padding-left:5px;}
.contact-cont p:first-child{line-height:2.0;margin-bottom:26px;}
.contact-cont p{line-height:2.0;margin-bottom:26px;}
ul.nav.menu_city li a{font-family:"montserratregular",sans-serif;font-size:12px;color:#a6a6a6 !important;padding:0 16px 0 0 !important;text-transform:uppercase;line-height:1;font-weight:600;text-decoration:none;}
ul.ca-rb-socialicons li span{background-image:url(../images/footerimage.png);display:block;background-repeat:no-repeat;}
ul.ca-rb-socialicons li span.ca-rb-facebook{background-repeat:no-repeat;background-position:-10px -11px;width:15px;height:27px;}
ul.ca-rb-socialicons li span a{font-size:0px;text-indent:-9999px;display:block;}
ul.ca-rb-socialicons li span.ca-rb-facebook{background-repeat:no-repeat;background-position:-10px -11px;width:15px;height:27px;}
.newfootersocial{float:left;clear:both;width:100%;}
.ca-rb-footer .newfootersocial{padding-top:26px;padding-left:5px;}
ul.ca-rb-socialicons li span.ca-rb-twitter{background-repeat:no-repeat;background-position:-37px -11px;width:25px;height:27px;}
.newthemerightbox h5{margin-top:0px;}
ul.ca-rb-socialicons li span.ca-rb-instagram{background-repeat:no-repeat;background-position:-75px -11px;width:23px;height:27px;}
ul.ca-rb-socialicons li span.ca-rb-googleplus{background-repeat:no-repeat;background-position:-110px -11px;width:23px;height:27px;}
ul.ca-rb-socialicons li span.ca-rb-youtube{background-repeat:no-repeat;background-position:-147px -11px;width:26px;height:27px;}
ul.ca-rb-socialicons li:first-child{margin-left:0;}
ul.ca-rb-socialicons li{margin-left:26px;display:inline-block;line-height:25px;}
.ca-rb-socialicons{padding:0px;margin:0px;}
.copylink{line-height:1;padding-top:25px;float:left;clear:both;width:100%;padding-left:5px;}
.newsletter-footer .button-sign{background:#3498db;border:none;width:77%;color:#fff;font-family:"montserratregular",sans-serif !important;font-size:8px;font-weight:500;letter-spacing:1px;margin:0px;display:inline-block;padding:3px 8px;}
.newsletter-footer.ftr-nws h4{float:left;font-size:8px;padding:13px 0px 13px 20px;font-family:"montserratregular",sans-serif !important;font-weight:500;letter-spacing:1px;margin:0px;color:#fff;}
.newsletter-footer .sign-img-footer{background-image:url(../images/arrowall.png);width:20px;height:20px;display:block;float:right;background-repeat:no-repeat;margin-right:10px;margin-top:8px;}
.newsletter-footer.ftr-nws h3{float:left;font-size:8px;padding:13px 0px 10px 5px;font-family:"montserratregular",sans-serif !important;font-weight:500;letter-spacing:1px;margin:0px;color:#fff;}
.carb-sec-left{float:left;width:270px;}
.sec-content-container.rb-sec-outerbox .newthememain{background-image:url(../images/menu-bg-color.jpg) !important;width:100%;background-repeat:repeat-y;}
.menu_arrow_show{background-image:url(../images/leftmenuright.png) !important;width:100%;background-repeat:no-repeat;background-position:right;}
.menu_arrow_hide{background-image:url(../images/leftmenudown.png) !important;width:100%;background-repeat:no-repeat;background-position:right;background-position:97%;}
.carb-sec-left-inner ul.responsivetheme li a{font-family:"montserratlight",sans-serif !important;font-size:13px;text-transform:uppercase;color:#fff;letter-spacing:1.5px !important;font-weight:500;padding-left:33px;display:inline-block;}
.carb-sec-left-inner ul.dropdown li a{text-transform:capitalize;}
.responsivetheme{padding:0px;margin:0px;}
.responsivetheme li{line-height:25px;width:100%;}
.carb-sec-left-inner .responsivetheme ul.dropdown li a:hover{border-left:0px solid #33495e;background-color:unset;padding-left:50px;}
.carb-sec-left-inner .responsivetheme ul.dropdown li a span:hover{color:#25bdef!important;}
.carb-sec-left-inner .responsivetheme ul.dropdown li a{padding-left:50px;}
.carb-sec-left-inner ul.responsivetheme li a:hover{border-left:10px solid #beca0f;background-color:#2c3e50;padding-left:23px;width:100%;}
.responsivetheme a{text-decoration:none;font-size:16px;}
ul.responsivetheme a span{color:#fff;padding:10px 0;display:block;text-decoration:none;}
.newthememain{clear:both;position:relative;width:100%;float:left;}
.sec-content-container{width:1340px;margin:0 auto;}
.orange_menu .ant-menu.left_menu{background-color:#33495e;}
.orange_menu .ant-menu.left_menu .ant-menu-submenu-title:hover{border-left:10px solid #beca0f;background-color:#2c3e50 !important;}
.orange_menu .ant-menu-dark .ant-menu-item:hover > a{color:#beca0f !important;}
.orange_menu .ant-menu-dark .ant-menu-item > a{color:#fff !important;}
.orange_menu .ant-menu.left_menu .ant-menu-submenu-selected .ant-menu-submenu-title{border-left:10px solid #beca0f;background-color:#2c3e50 !important;}
.orange_menu .ant-menu-dark .ant-menu-item:hover > a{color:#beca0f !important;opacity:1 !important;}
.orange_menu .ant-menu-dark .ant-menu-item > a{color:#fff !important;opacity:1 !important;}
.orange_menu .ant-menu.left_menu .ant-menu-item-selected{background-color:transparent !important;color:#beca0f !important}
.orange_menu .ant-menu.left_menu .ant-menu-item-selected span{color:#beca0f !important;}
.orange_menu .ant-menu.left_menu .ant-menu-item-selected span:hover{color:#beca0f !important;}
.ant-menu-dark .ant-menu-item:hover > a{color:#3498db !important;opacity:1 !important;}
.ant-menu-dark .ant-menu-item > a{color:#fff !important;opacity:1 !important;font-family:"montserratlight",sans-serif !important;font-size:13px;text-transform:capitalize;padding-right:25px;line-height:1.7;letter-spacing:1.5px !important;font-weight:500;}
.ant-menu-sub.ant-menu-inline > .ant-menu-item{line-height:40px !important;}
.ant-menu.left_menu .ant-menu-item-selected{background-color:transparent !important;color:#beca0f !important}
.ant-menu.left_menu .ant-menu-item-selected span{color:#3498db !important;}
.ant-menu.left_menu .ant-menu-item-selected span:hover{color:#3498db !important;}
.ant-menu.left_menu .ant-menu-submenu-selected .ant-menu-submenu-title{border-left:10px solid #3498db;background-color:#2c3e50 !important;}
.ant-menu-dark.ant-menu-inline .ant-menu-item{white-space:normal;width:100%;display:table;}
.ant-menu.left_menu{background-color:#33495e;}
.ant-menu.left_menu .ant-menu-submenu-title:hover{border-left:10px solid #3498db;background-color:#2c3e50 !important;}
.ant-menu.left_menu .ant-menu-submenu-title{padding:2px 0px;display:table;border-left:10px solid #33495e;padding-right:25px;}
.ant-menu-submenu-title{margin:0px !important;}
.ant-menu-submenu-arrow{display:none;}
.ant-menu.left_menu .ant-menu-submenu-inline .ant-menu-submenu-title{background-image:url(../images/leftmenuright.png) !important;background-repeat:no-repeat;background-position:98%;color:#fff;white-space:normal;}
.ant-menu.left_menu .ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title{background-image:url(../images/leftmenudown.png) !important;background-repeat:no-repeat;background-position:96%;white-space:normal;}
.ant-menu.left_menu span{font-family:"montserratlight",sans-serif !important;font-size:13px;text-transform:uppercase;letter-spacing:1.5px !important;font-weight:500;margin-right:20px;}
.ant-menu.left_menu .ant-menu-inline.ant-menu-sub span{text-transform:capitalize;font-family:"montserratlight",sans-serif !important;font-size:13px;letter-spacing:1.5px !important;font-weight:500;}
.ant-menu.left_menu .ant-menu-inline.ant-menu-sub{background:#33495e;box-shadow:none;}
.ant-menu.left_menu .ant-menu-submenu-arrow, .ant-menu.left_menu .ant-menu-submenu-arrow{opacity:1 !important;}
.carb-menutitle{background:#2c3e50;padding:25px 22px;}
.carb-sec-left-inner{width:270px;}
.carb-sec-right{float:right;width:1070px;}
.ca-rb-common-leftpad{padding-left:30px;clear:both;}
.ca-hide{display:none;}
.ca-show{display:block;}
.ca-rb-footer ul.nav.menu_footer li a:hover, ul.nav.menu_footer li a:hover{color:#3498db !important;font-weight:normal !important;}
@media only screen and (max-width:767px){.layout{width:100%;}
 .newthemeleftbox{width:100%;padding:0px 15px;box-sizing:border-box;}
 .newthemerightbox, .newthemerightbox{width:100%;padding:0px 15px;box-sizing:border-box;}
 .ca-rb-footer .newthemefooternode2{width:100%;}
 .ca-rb-footer .newthemefooternode4{width:100%;}
 .ca-rb-footer .newthemefooternode1{width:100%;}
 .sec-content-container.rb-sec-outerbox .newthememain{width:100%;background-image:none !important;}
 .sec-content-container.rb-sec-outerbox .newthememain .carb-sec-left{display:none;}
 .carb-sec-right{width:100%;padding:0px 15px;box-sizing:border-box;}
 .newsletter-footer .button-sign{width:auto;}
 .sec-content-container{width:100%;}
 .ca-rb-common-leftpad{padding:0px;}
 .newthemefooterbox{padding-left:0px;}
 .newthemefooternode4{padding-top:50px;}
 .newthemerightbox{padding-top:0px;}
 .ca-hide{display:block !important;}
 ul.nav.menu_city li{display:block;}
.ca-show{display:none;}
}
@media (min-width:768px) and (max-width:1024px) and (orientation:portrait){.sec-content-container.rb-sec-outerbox .newthememain{width:100%;background-image:none !important;}
 .sec-content-container.rb-sec-outerbox .newthememain .carb-sec-left{display:none;}
 .carb-sec-right{width:100%;padding:0px 15px;box-sizing:border-box;}
 .sec-content-container{width:100%;}
 .newsletter-footer .button-sign{width:auto;}
 .sec-content-container{width:100%;}
 .ca-rb-common-leftpad{padding:0px;}
 .layout{width:100%;}
 .newthemeleftbox{width:100%;padding:0px 15px;box-sizing:border-box;}
 .newthemerightbox, .newthemerightbox{width:100%;padding:0px 15px;box-sizing:border-box;}
 .ca-rb-footer .newthemefooternode2{width:100%;}
 .ca-rb-footer .newthemefooternode4{width:100%;}
 .ca-rb-footer .newthemefooternode1{width:100%;}
 .checkoutinformationbox{width:100% !important;}
 .newthemefooterbox{padding-left:0px;}
}
@media (min-width:768px) and (max-width:1024px) and (orientation:landscape){.ca-rb-footer .newthemefooternode2{width:25%;}
 .ca-rb-footer .newthemefooternode4{width:25%;}
 .ca-rb-footer .newthemefooternode1{width:25%;}
 .ca-rb-footer .newthemerightbox{width:25%;}
 .ca-rb-footer .newthemeleftbox{width:100%;float:none;}
 .newsletter-footer .button-sign{width:auto;}
 .ca-rb-footer .layout{width:100%;padding:0px 15px;box-sizing:border-box;}
 .newthemeleftbox.ftr_link{width:75%;}
 .newthememain, .sec-content-container{width:100% !important;box-sizing:border-box;}
 .carb-sec-left{width:270px !important;}
 .carb-sec-right{width:73% !important;}
 .ca-rb-common-leftpad{padding-left:20px;}
 .newthemefooterbox{padding-left:0px;}
}
