
    *{
    box-sizing:border-box;
    margin:0;
    }
   
    .login-header, .ant-layout-footer{display:none;}
    .login-left {
        width: 40%;
        float: left;
        background: #fff;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 30px;
    }
    .login-right {
        width: 60%;
        float: left;
        background: var(--primarycolor);
        padding: 88px;
        color: #fff;
        border-top-right-radius: 20px;
    }
    .login-right h3 {font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;}
    .login-right p {font-size: 16px;
        color: #fff;}
.login-page {
    background: #f3f3f3;
    display: flex;
    height: 100%;
    position: fixed;
    padding-top: 5%;
}
.login-box{background: var(--primarycolor);
    width: 100%;box-shadow: 8px 7px 8px #0000007a;
    border-radius: 20px;
    display: inline-block;
    padding: 0px;}
    
.conatiner{width:70%;margin:0 auto;}
    .ant-layout-content{margin:0px !important;}
    .login-container{
    width:100%;
    margin:0 auto;
    min-height:400px;
    }
    .login-container img {
        text-align: center;
        margin: 0 auto;
        display: block;
        padding-top: 9px;
    }
    .header{
    background-color: #222328;
    padding:15px 20px;
    }
    .login-header {
        height: 160px !important;
        padding: 0 50px;
        line-height: 64px;
      
    }
    .img-center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width:350px;
    }
    .login-heading {
        text-align: center;
        font-size: 20px;
        padding-bottom: 0px; 
        padding-top:15px;
    font-family: montserratbold,sans-serif!important;
    }
    .border{
    border-bottom:5px solid #339ad5!important;
    width:45px;
    }
    .row-label{
    width:100%;
    float:left;
    }
    .row-label label {
        font-size: 16px;
        padding-bottom: 30px;
        color: #343e5c!important;
        font-family: montserratlight,sans-serif!important;
        font-weight: bold;
        text-transform: uppercase;
    }
    .login-textbox{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom:10px;
    }
    .forgot-pwd{
    width:50%;
    float:left;
    }
    .login-btn{
   width:100%;
    text-align:center;
    }
    .login-btn-style {
        cursor: pointer;
        background: var(--primarycolor) !important;
        padding: 14px 56px!important;
        border: 0!important;
        line-height: 1!important;
        font-family: montserratregular,sans-serif!important;
        font-size: 11px!important;
        color: #fff!important;
        letter-spacing: 2px!important;
        text-transform: uppercase;
        width: 100%;
        border-radius: 20px;
    }
  
    .forgot-pwd{
    padding-top:16px;
    }
    .forgot-pwd a{
    font-size: 12px!important;
    color: #3498db!important;
    font-family: montserratlight,sans-serif!important;
    font-weight: 400;
    letter-spacing:2px;
    }
    .footer{
    background-color:#222328;
    padding:22px 15px;
    width:100%;
    position:fixed;
    bottom:0;
    }
    .footer-center{
      text-align:center;
    }
    .footer-center a{
    padding:0px 5px;
    color:#fff;
    text-transform:uppercase;
    font-size:12px;
    font-family: montserratregular,sans-serif!important;
    } 
    .row:after {
    content: "";
    display: table;
    clear: both;
    }  .ant-layout{height: 100vh;background: #171d29;}
    .login_logo{width:180px;} 
@media screen and (max-width: 767px) {
    .row-label{
    width: 100%;
    margin-top: 0;
    }
    .ant-layout{height: 100vh;background: #171d29;}
    .login-page{position: absolute;display: inline-flex;}
    .login-page {
        position: relative;
        display: inherit;
        /* background: red; */
        height: 100%;
        padding-top:60px;
    }
    .login-container{
    width:94%;
    }
    .login-left{width:100%;border-radius: 20px;}
    .conatiner {
        width: 90%;
        margin: 0 auto;
    }.login-right{display:none;}
    .img-center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width:200px;
    }
    .forgot-pwd{
    width:100%;
    float:left;
    padding-top:0px;
    padding-bottom:10px;
    }
    .login-btn{
    float:none;
    }
    .footer-center a{
    padding:0px 5px;
    color:#fff;
    text-transform:uppercase;
    font-size:12px;
    font-family: montserratregular,sans-serif!important;
    }
    .login-mt{
    margin-top:0px;
    }
}

@media screen and (min-width:768px) and (max-width:1024px){
    .login-container{
    width:40%;
    }
    .img-center{
    max-width:200px;
    }
}

.themesmta .logo-login {
    width: 100%;
    height: 70px;    
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: center;
}

.themesmta .logo-login  img{   
    height: 70px;   
}

.themesmtf  .logo-login {
    width: 100%;
    height: 70px;    
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: center;
}

  .erromessage{
      color:#ff0000;
  }
  .login-forgot{    width: 100%;
    padding-top: 70px;
    text-align: center;}
  .login-forgot p{margin-bottom: 0px;font-weight: bold;}
  .login-forgot a{text-decoration:underline;color:#339ad5;}




  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .login-container{
        width:94%;
        }
        .login-left{width:100%;border-radius: 20px;}
        .conatiner {
            width: 90%;
            margin: 0 auto;
        }.login-right{display:none;}
        .img-center{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        max-width:200px;
        }
        .login-page .container{
            width: 50%;}
        .login-page {padding-top: 5%;
            margin: 0 auto;
            display: block;
            width: 100%;}

            .logo-login {
                width: 100%;
                height: 129px;
                background-size: 79% !important;
               
              }
              .login-box {
                background: var(--primarycolor);
                width: 75%;
                box-shadow: 8px 7px 8px #0000007a;
                border-radius: 20px;
                display: block;
                padding: 0px;
                margin: 0 auto;
              }
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .login-container{
        width:94%;
        }
        .login-left{width:100%;border-radius: 20px;}
        .conatiner {
            width: 90%;
            margin: 0 auto;
        }.login-right{display:none;}
        .img-center{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        max-width:200px;
        }
        .login-page .container{
            width: 50%;}
        .login-page {padding-top: 5%;
            margin: 0 auto;
            display: block;
            width: 100%;}

            .logo-login {
                width: 100%;
                height: 129px;
                background-size: 79% !important;
               
              }
              .login-box {
                background: var(--primarycolor);
                width: 70%;
                box-shadow: 8px 7px 8px #0000007a;
                border-radius: 20px;
                display: block;
                padding: 0px;
                margin: 0 auto;
              }
  }
  .forgot-pwd a
  {
    color:var(--primarycolor) !important;
  }