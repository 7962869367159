.commonbodylayout .ant-breadcrumb {
    margin: 64px 0px 0px 0px !important;
    background: #fff;
    padding: 10px;
    position: fixed;
    width: 100%;
    box-shadow: 0px 3px 2px #a79e9f;
    z-index: 9999;
    border-top: 1px solid #d7d7d7;
    font-weight: bold;
  }
  .commonbodylayout .ant-layout-content{
      margin-top: 120px !important;
  }

  .commonbodylayout .dasboard-page .ant-collapse-header{
      background: #f3f3f3 !important;
      color: #000 !important;
  }