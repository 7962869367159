#header {
    position: relative;
    color: #fff;
}
*, body{font-size: 14px !important;}
header {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;
    transition: height .3s;
}

.ca-rb-header {
    clear: both;
}

.ca-rb-header.sticky_header {
    background: #222328;
    padding: 5px 0 !important;
    padding-bottom: 0px !important;
    position: fixed;
    width: 100%;
}

.ca-rb-header.sticky_header span#logo a {
    background-image: url(../images/ca-logo-black.png);
    font-size: 0 !important;
    height: 55px;
    text-indent: -999px;
    width: 318px;
    display: inline-block !important;
    transition: none !important;
    background-repeat: no-repeat !important;
    margin-top: 15px;
}

.ca-rb-header.sticky_header .business-bg {
    background: #fff;
    height: 80px;
}

.ca-rb-header.sticky_header .carb-menu ul.nav {
    padding-top: 18px;
}

.ca-rb-header.sticky_header .carb-menu ul.nav li a {
    color: #222328;
}

.ca-rb-header.sticky_header .carb-search {
    padding-top: 30px;
}

.ca-rb-header.sticky_header .sticky-topbg {
    padding: 7px 0px;
    padding-bottom: 17px;
}

header .ca-rb-header .sticky-topbg {
    padding: 12px 0px;
    padding-bottom: 8px;
    overflow: hidden;
}

.carb-newthemebasket .carb-topinfo-pad a {
    margin-top: 5px;
}

.carb-topinfo-pad button {
    background: none;
    border: none;
    padding-right: 7px;
    padding-left: 7px;
    cursor: pointer;
    outline: none;
}

.ca-rb-header-info {
    text-align: right;
    font-family: "montserratregular", sans-serif !important;
    font-size: 12px;
    letter-spacing: 1px;
    right: 0;
    line-height: 21px;
    z-index: 1;
}

.right_bar {
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
}

.carb-phone-email.manchester {
    float: left;
}

.ca-rb-header .carb-phone-email {
    padding-right: 65px;
}

.ca-rb-header .layout {
    position: relative;
}

.layout {
    width: 1290px;
    margin: 0 auto;
}

.carb-phone-email.manchester {
    float: left;
}

.ca-rb-header .carb-phone-email {
    padding-right: 65px;
}

.carb-topinfo-pad,
.ca-rb-header .carb-phone-email span {
    padding-top: 1px;
    display: inline-block;
}

.ca-rb-header .carb-phone-email span {
    padding: 0 7px;
    padding-top: 0px;
}

.ca-rb-header-info a {
    color: #fff !important;
    text-decoration: none;
}

.signup-news {
    position: relative;
    right: 50px;
    font-family: "montserratregular", sans-serif !important;
    font-size: 11px;
}

.signup-news img {
    width: 26px;
    height: 25px;
    left: -28px;
    position: absolute;
    display: inline-block;
    top: -6px;
}

.carb-newthemebasket .carb-topinfo-pad {
    padding-right: 5px;
}

.carb-topinfo-pad,
.ca-rb-header .carb-phone-email span {
    padding-top: 1px;
    display: inline-block;
}

.containerminicart {
    margin: auto;
    width: 100%;
    float: right;
    position: absolute;
}

.ca-rb-wrapper #header .topsectioncart {
    width: 19px !important;
}

#header .topsectioncart {
    padding-top: 0px;
}

#header .topsectioncart {
    float: right;
    margin: 0px 0px 0px 6px;
}

.ca-rb-header .layout .topsectioncart {
    background-repeat: no-repeat;
    background-position: -12px -42px;
    width: 19px;
    height: 27px;
    display: block;
}

.ca-rb-header .layout .topsectioncart {
    text-align: center;
    padding: 0 !important;
}

.container {
    width: 1290px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.carb-logo {
    float: left;
    width: 29.1%;
}

.carb-menu {
    float: left;
    width: 66%;
}

.ca-rb-header .carb-search {
    float: right;
    padding-top: 68px;
    padding-right: 2px;
}

.carb-menu ul.nav {
    padding-top: 53px;
    padding-left: 0px;
    margin-top: 16px;
}

#logo {
    float: left;
}

ul.nav li {
    display: inline;
    list-style: none outside none;
}

.ca-rb-header ul.nav li a {
    padding: 4px 35px 8px 0 !important;
    letter-spacing: 1.5px;
}

.carb-menu ul.nav li a {
    font-family: "montserratregular", sans-serif !important;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
}

.carb-menu ul.nav li a:hover {
    background-image: url(../images/ca-rb-menu-black-hover.png);
    background-repeat: no-repeat;
    background-position: bottom left;
}

.ca_content {
    clear: both;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.header_bg {
    background-image: url(../images/singing_banner.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;
    width: 100%;
    height: 297px;
    margin: 0;
    display: block;
    clear: both;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.header_bg.sticky {
    display: inline-block;
}

header span#logo a {
    background-image: url(../images/ca-logo.png);
    font-size: 0 !important;
    height: 85px;
    text-indent: -999px;
    width: 318px;
    display: inline-block !important;
    transition: none !important;
}

.mobilebasketbox {
    width: 40px;
    height: 30px;
    background-image: url(../images/mbasket.png);
    text-align: center;
    padding: 0 !important;
    background-repeat: no-repeat;
    margin-top: 15px;
    float: left;
    margin-right: 13px;
}

.mobilesearch {
    width: 40px;
    height: 30px;
    background-image: url(../images/msearch.png);
    text-align: center;
    padding: 0 !important;
    background-repeat: no-repeat;
    margin-top: 15px;
    float: left;
    margin-right: 13px;
}

.mobilemenu {
    width: 40px;
    height: 30px;
    background-image: url(../images/mobilemenuicon.png);
    text-align: center;
    padding: 0 !important;
    background-repeat: no-repeat;
    margin-top: 15px;
    margin-right: 13px;
    float: left;
}

.mobileleftbox {
    float: right;
    width: 46%;
    display: inline-block;
}

ul.pagenavigation li:first-child {
    padding-left: 0 !important;
}

ul.pagenavigation li {
    display: inline;
    line-height: 1;
    list-style: none outside none;
}

ul.pagenavigation li span {
    font-size: 11px;
    font-family: "montserratlight", sans-serif !important;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.pathway {
    font-size: 11px;
    padding-right: 3px;
    text-decoration: none;
    color: #f89247 !important;
    font-family: "montserratlight", sans-serif !important;
}

ul.pagenavigation li span.divider {
    padding-right: 5px;
    font-size: 11px;
    color: #443e4c;
}

.carb-sec-right ul.pagenavigation li.active span {
    color: #222328;
}

ul.pagenavigation li.active span {
    color: #443e4c;
}

.mobilemenu {
    display: none;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0px;
}

.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0em;
}

.bm-item {
    display: inline-block;
}

.bm-menu #home {
    background-image: url(../images/pm-home.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #about {
    background-image: url(../images/pm-aboutus.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #contact {
    background-image: url(../images/pm-contact.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-home {
    background-image: url(../images/pm-home.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-aboutus {
    background-image: url(../images/pm-aboutus.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-contact {
    background-image: url(../images/pm-contact.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-ourclasses {
    background-image: url(../images/pm-ourclasses.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-locations {
    background-image: url(../images/pm-locations.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-forbussiness {
    background-image: url(../images/pm-forbussiness.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-specialoffers {
    background-image: url(../images/pm-specialoffers.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-giftvouchers {
    background-image: url(../images/pm-gifts.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-aboutus {
    background-image: url(../images/pm-aboutus.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-youraccount {
    background-image: url(../images/pm-youraccount.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-blog {
    background-image: url(../images/pm-blog.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-shows {
    background-image: url(../images/pm-shows.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #pm-shows {
    background-image: url(../images/pm-shows.png);
    padding-left: 40px;
    background-repeat: no-repeat;
    background-position: 0% 45%
}

.bm-menu #drop-menu {
    padding-left: 15%;
    text-transform: capitalize;
}

.bm-menu .drop-active {
    background-image: url(../images/pm-mopen.png);
    padding-left: 0px;
    background-repeat: no-repeat;
    background-position: 100%
}

.bm-menu .drop-hide {
    background-image: url(../images/pm-mclose.png);
    padding-left: 0px;
    background-repeat: no-repeat;
    background-position: 100%;
}

.bm-cross-button {
    background-image: url(../images/mobileclose.png);
    background-repeat: no-repeat;
    outline: none;
    top: 23px !important;
    right: 15px !important;
}

.bm-cross {
    background: none;
}

.bm-item.ca-push-header {
    margin-top: -25px;
}

.bm-item.ca-push-header a {
    font-family: "montserratsemibold", sans-serif;
    font-size: 28px !important;
    letter-spacing: 1px;
    color: #222328 !important;
    text-decoration: none;
    padding: 0px;
    text-transform: unset;
}

.bm-item {
    text-align: left;
}

.bm-item-list a {
    padding: 13px 0;
    color: #222328 !important;
    border: 0 none !important;
    font-family: "montserratlight", sans-serif;
    font-size: 16px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    clear: both;
    display: inline-block;
}

.bm-overlay {
    background: none;
}

.mobile_logout {
    vertical-align: middle;
}

.mobilemenubox a {
    text-decoration: none;
}

@media only screen and (min-device-width:320px) and (max-device-width:568px)and (-webkit-device-pixel-ratio:2) and (device-aspect-ratio:40/71) and (orientation:portrait) {
    .mobile_ca_header .mob-ca-header .mobileleftbox {
        width: auto !important;
    }

    ul.nav.menu_city li {
        display: inline;
    }

    .mobile_ca_header .mobilemenu {
        margin-left: 5px;
    }

    .mobile_ca_header .mobilebasketbox,
    .mobile_ca_header .mobilesearch {
        margin-left: 5px;
    }
}

@media only screen and (max-width:767px) {
    .header_bg {
        background-image: none;
        display: none;
    }
.basketicon svg{color:#fff !important;}
.profile_info_left .mwidth-auto{width:100% !important;}
    .ca-rb-header {
        display: none;
    }

    header {
        height: auto;
    }

    .mob-ca-header {
        background: #222328;
        height: 72px;
        padding: 13px 13px;
        overflow: hidden;
    }

    .mobilemenu {
        display: block;
    }

    .mob-ca-header .canewthememobilelogo img {
        width: 78%;
        padding-top: 7px;
    }

    .mob-ca-header .topsectioncart a {
        color: #fff;
        font-size: 12px;
        padding: 5px 0;
        font-family: "montserratlight", sans-serif !important;
    }

    .mob-ca-header .topsectioncart {
        margin-top: 6px !important;
        display: inline-block;
        margin-right: 7px;
    }

    .newsletter_popup_msg .popup {
        width: 100% !important;
    }

    .header_bg.sticky {
        display: none;
    }

    .mob-ca-header .canewthememobilelogo {
        width: 48%;
        float: left;
        text-align: left;
    }

    .mobile_ca_header .mob-ca-header .mobileleftbox {
        width: auto;
    }

    .mobilemenu {
        margin-left: 19px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
        margin-top: 8px;
    }

    .mobilesearch {
        margin-left: 17px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
        margin-top: 8px;
    }

    .mobilebasketbox {
        margin-left: 9px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
        margin-top: 8px;
    }
}

@media only screen and (max-width:767px) and (orientation:landscape) {
    .mob-ca-header .canewthememobilelogo img {
        width: 47%;
        padding-top: 7px;
    }

    .mobile_ca_header .mob-ca-header .mobileleftbox {
        width: auto;
    }
}

@media (min-width:768px) and (max-width:1024px) and (orientation:portrait) {
    .header_bg {
        background-image: none;
        display: none;
    }

    .ca-rb-header {
        display: none;
    }

    header {
        height: auto;
    }

    .mob-ca-header {
        background: #222328;
        height: auto;
        padding: 10px 10px;
        overflow: hidden;
    }

    .mobile_ca_header .mob-ca-header .mobileleftbox {
        width: auto;
    }

    .mobilemenu,
    .mobilesearch,
    .mobilebasketbox {
        margin-left: 6px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
    }

    .mobileleftbox {
        float: inline-end;
        width: 22%;
        display: inline-block;
    }

    .mobilemenu {
        display: block;
    }

    .mob-ca-header .canewthememobilelogo img {
        width: 45%;
        padding-top: 7px;
    }

    .mob-ca-header .topsectioncart a {
        color: #fff;
        font-size: 12px;
        padding: 5px 0;
        font-family: "montserratlight", sans-serif !important;
    }

    .mob-ca-header .topsectioncart {
        margin-top: 6px !important;
        display: inline-block;
        margin-right: 7px;
    }

    .header_bg.sticky {
        display: none;
    }

    .mob-ca-header .canewthememobilelogo {
        width: 48%;
        float: left;
        text-align: left;
    }

    .mobile_ca_header .mob-ca-header .mobileleftbox {
        width: auto;
    }

    .mobilemenu {
        margin-left: 19px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
        margin-top: 8px;
    }

    .mobilesearch {
        margin-left: 17px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
        margin-top: 8px;
    }

    .mobilebasketbox {
        margin-left: 9px;
        margin-right: 0px;
        width: 40px;
        background-position: right;
        margin-top: 8px;
    }

    .newsletter_popup_msg .popup {
        width: 48% !important;
    }
}

@media (min-width:768px) and (max-width:1024px) and (orientation:landscape) {
    .layout {
        width: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    .container {
        width: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    header span#logo a {
        background-repeat: no-repeat;
        background-size: 60%;
    }

    .carb-logo {
        width: 20%;
    }

    .right_bar {
        position: relative;
    }

    .carb-menu {
        width: 80%;
    }

    .ca-rb-header .carb-search {
        float: right;
        top: 28%;
        padding-right: 2px;
        position: absolute;
        right: 1%;
        display: inline-block;
        padding-top: 0px;
    }

    .carb-menu ul.nav li a {
        font-size: 14px;
        font-weight: 600;
        padding: 4px 25px 8px 0 !important
    }

    .carb-menu ul.nav {
        padding-top: 25px;
    }

    .header_bg.sticky .ca-rb-header.sticky_header .carb-search {
        padding-top: 48px;
    }

    .newsletter_popup_msg .popup {
        width: 35% !important;
    }
}

.newsletter_popup_msg .popup {
    margin: 0 auto;
    padding: 20px 30px 20px 30px;
    background: #fff;
    width: 28%;
    position: absolute;
    top: 0;
    right: 0;
    border-top: 8px solid #6f4d9f;
}

.newsletter_popup_msg .sign-news-letter {
    font-family: "montserratlight", sans-serif;
    font-size: 22px;
    letter-spacing: 3.4px;
    font-weight: 500;
    margin-bottom: 1px;
}

.newsletter_popup_msg .popup-img {
    background-image: url(../images/arrow-across.png);
    background-repeat: no-repeat;
    width: 100%;
    background-position: 84% 50%;
    height: 86px;
}

.newsletter_popup_msg .newsletter_form .main {
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.newsletter_popup_msg .tick_text {
    color: #1d2735 !important;
    width: 88%;
    float: right;
    margin-left: 9px;
    font-size: 10px !important;
    font-family: "montserratlight", sans-serif !important;
    letter-spacing: 1px;
}

.newsletter_popup_msg .inputboxpads.checkbox .check_box {
    width: 100%;
}

.newsletter_popup_msg .tick-box input {
    width: 0px;
    height: 0px !important;
    margin: 0px !important;
}

.newsletter_popup_msg .news-letter {
    font-family: "montserratbold", sans-serif;
    font-size: 22px;
    color: #2f3240;
    letter-spacing: 3.4px;
    font-weight: 100;
}

.newsletter_popup_msg .popup-inbox {
    font-size: 12px !important;
    line-height: 1.5 !important;
    font-family: "montserratlight", sans-serif !important;
    color: #1d2735 !important;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.newsletter_popup_msg .Sign-up #btnnewsubmit {
    background: #6f4d9f;
    border: none;
    width: 100%;
    color: #fff;
    font-family: "montserratregular", sans-serif !important;
    font-size: 11px;
    padding: 11px 0;
}

.newsletter_popup_msg .read-our {
    font-size: 11px;
    margin-top: 10px;
    line-height: 1.8 !important;
    font-family: "montserratlight", sans-serif !important;
}

.newsletter_popup_msg .read-our a {
    color: #6f4d9f;
}

.newsletter_popup_msg .text-full input {
    margin: 18px 18px 0 0;
    border: 1px solid #ccc;
    padding: 20px 0 20px 15px;
    font-family: "montserratlight", sans-serif !important;
    height: 35px;
    height: 35px;
    outline: none;
    background: #fff;
    font-size: 11px !important;
    color: #737374;
    background: transparent !important;
}

.newsletter_popup_msg .text-form-half {
    width: 47%;
    float: left;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-right: 3%;
}

.newsletter_popup_msg .text-form-half.last {
    margin-left: 3%;
    width: 47%;
    float: left;
    margin-right: 0px;
}

.newsletter_popup_msg .text-form-half input {
    margin-left: 0px;
    width: 100%;
}

.newsletter_popup_msg .text-form-half.last input {
    margin-left: 0px;
    width: 100%;
}

.newsletter_popup_msg .text-form-email {
    width: 100%;
    display: grid;
    overflow: hidden;
    margin-bottom: 0px;
}

.newsletter_popup_msg .geekmark {
    border: 2px solid #cacece;
    color: #fff;
    padding: 9px;
}

.newsletter_popup_msg .main .geekmark::after {
    color: #333;
    content: '\2714';
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: -6px;
    left: 0px;
    -moz-appearance: none;
}

.newsletter_popup_msg .popup_close {
    position: absolute;
    top: -8px;
    right: 18px;
    transition: all 200ms;
    font-size: 32px;
    font-weight: 500;
    text-decoration: none;
    color: #333;
}

.newsletter_popup_msg .text-form-email input {
    margin: 0px;
}

.right_bar .ant-btn {
    background: none !important;
    outline: none !important;
    border: none !important;
    padding: 0px !important;
}

.right_bar .signup-news .ant-btn span {
    padding-left: 5px;
    font-family: "montserratregular", sans-serif !important;
    font-size: 11px;
    letter-spacing: 1px;
}

.newsletter_popup_msg {
    top: 0px !important;
    transform-origin: unset !important;
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0 !important;
    ;
    right: 0 !important;
    ;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.newsletter_popup_msg .ant-popover-arrow {
    display: none;
}

#header .topsectioncart.minicart .minibasket_bg {
    background-image: url(../images/basket.png);
    background-repeat: no-repeat;
    width: 19px;
    height: 17px;
    margin-top: 6px;
}

.tets {

    background: #fe6d4c;
    border-radius: 50%;
    height: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 20px;
    font-size: 11px;
}

.empty-cart {

    padding: 15px;
    text-align: center;
    margin: 0px;
    background: #fff;
    font-family: "montserratlight", sans-serif !important;
    color: #242529 !important;
    font-size: 13px !important;
    text-align: center;
    padding-bottom: 20px !important;
    letter-spacing: 1px;
}

.ant-layout-sider-zero-width-trigger {
    background: #c33131;
}

.box-model-manage {
    margin: 20px !important;
}

.sideright_menu .ant-menu-item {
    padding-left: 10px !important;
}

.sideright_menu .ant-menu-item {
    margin: 0px !important;
    border-bottom: 1px solid #fff;
    padding: 4px;
    height: 55px;
}

.menuicon {
    width: 35px;
    margin-right: 10px;
    margin-top: -6px;
    /* background: #a31e1d; */
    padding: 4px;
    border-radius: 5px;
    /* box-shadow: -4px 4px 2px #fff; */
}

.ant-layout .sideright_menu .ant-menu-item:hover {
    border-left: 10px solid #000000 !important;
    background-color: #000000 !important;
}


.btm-search.ant-btn-primary {
    border-radius: 20px;
    background: #3e9f0f;
}

.btm-search.ant-btn-primary:hover {
    border-radius: 20px;
    background: #29660b;
}


.btm-reset.ant-btn-primary {
    border-radius: 20px;
    background: #c33131;
}

.btm-reset.ant-btn-primary:hover {
    border-radius: 20px;
    background: #751414;
}

.rightsettings {
    padding-right: 15px;
}

.profile-menu li {
    padding: 0px;
}

.profile-menu {
    padding: 0px;
    top: 20px
}

.profile-menu a {
    padding: 13px 15px;
    display: inline-block;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: bold;
}

.profile-menu button {
    border: none;
    background: #a31e1d;
    width: 100%;
    color: #fff;
    font-size: 14px;
    padding: 9px;
    font-weight: bold;
    text-transform: uppercase;
}

.logo2 {
    background-position: 11px 4px;
}
.ant-layout .dashboard-common .ant-collapse-header {
    background: #d7d7d7 !important;
    font-weight: bold;
    text-transform: capitalize;
    color: #333 !important;
}
.ant-switch{width:44px !important;}
.ant-btn-primary {
    box-shadow: -3px 4px 1px rgb(167 160 160);
    margin-bottom: 5px;
    height: 35px !important;
}
.ant-layout .dashboard-common .ant-list-item-action button{color:#000 !important}
.ant-layout .dashboard-common .ant-list-item-action a{color:#000 !important}
.btm-reset {
    border-radius: 20px;
    background: #c33131;
    padding: 3px 32px;
    margin-right: 10px;
    border: none;
    font-weight: 600;
    font-size: 14px;
}

.btm-addnew {
    border-radius: 20px;
    background: #376495;
    padding: 3px 32px;
    margin-right: 10px;
    border: none;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}

.ant-list-item-action a:hover {
    opacity: 0.8;
}
.btm-review{border-radius: 20px;margin-left: 3px;}
.manage_course_info input, .manage_course_info select {    border: 1px solid #ddd;
    height: 32px;
    border-radius: 5px;padding: 5px;}

    .width-auto {
        width: auto !important;
    }
    .rightsettings .user_details, .rightsettings a {
        font-size: 13px !important;
        font-variant: tabular-nums;
        line-height: 1.5;
        font-feature-settings: 'tnum', "tnum";
        letter-spacing: 0px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      }
    .rightsettings .ant-avatar-circle{margin-left: 0px;}
    .basketicon svg {
        width: 25px;
        height: 17px;
        color: #c33131;
      }
      .rightsettings .user_details{font-size: 14px;}
    .basketicon span{margin-left: 4px;
        font-size: 13px !important;
        font-feature-settings: 'tnum', "tnum";
        letter-spacing: 0px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }

    .width-full{width:100%;display: inline-block;}
    .mwidth-auto{width:auto !important;}

    #credits_pay{
        width: 200px;
    }