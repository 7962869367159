

@font-face {
    font-family: 'roboto_slablight';
    src: url('./fonts/robotoslab-light-webfont.eot');
    src: url('./fonts/robotoslab-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/robotoslab-light-webfont.woff') format('woff'),
         url('./fonts/robotoslab-light-webfont.ttf') format('truetype'),
         url('./fonts/robotoslab-light-webfont.svg#roboto_slablight') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'knockout-27';
    src: url('./fonts/knockout-27-webfont.eot');
    src: url('./fonts/knockout-27-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/knockout-27-webfont.woff') format('woff'),
         url('./fonts/knockout-27-webfont.ttf') format('truetype'),
         url('./fonts/knockout-27-webfont.svg#knockout-27') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'knockout28juniorfeatherweight';
    src: url('./fonts/knockout-28-webfont.eot');
    src: url('./fonts/knockout-28-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/knockout-28-webfont.woff') format('woff'),
         url('./fonts/knockout-28-webfont.ttf') format('truetype'),
         url('./fonts/knockout-28-webfont.svg#knockout28juniorfeatherweight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proximanova-reg';
    src: url('./fonts/proximanova-reg-webfont.eot');
    src: url('./fonts/proximanova-reg-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/proximanova-reg-webfont.woff') format('woff'),
         url('./fonts/proximanova-reg-webfont.ttf') format('truetype'),
         url('./fonts/proximanova-reg-webfont.svg#proximanova-reg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Conv_BodoniStd-PosterItalic';
    src: url('./fonts/BodoniStd-PosterItalic.eot');
    src: url('./fonts/BodoniStd-PosterItalic.eot?#iefix') format('embedded-opentype'),
         url('./fonts/BodoniStd-PosterItalic.woff') format('woff'),
         url('./fonts/BodoniStd-PosterItalic.ttf') format('truetype'),
         url('./fonts/BodoniStd-PosterItalic.svg#Conv_BodoniStd-PosterItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'roboto_slabthin';
    src: url('./fonts/robotoslab-thin-webfont.eot');
    src: url('./fonts/robotoslab-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/robotoslab-thin-webfont.woff') format('woff'),
         url('./fonts/robotoslab-thin-webfont.ttf') format('truetype'),
         url('./fonts/robotoslab-thin-webfont.svg#roboto_slabthin') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'roboto_slabregular';
    src: url('./fonts/robotoslab-regular-webfont.eot');
    src: url('./fonts/robotoslab-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/robotoslab-regular-webfont.woff') format('woff'),
         url('./fonts/robotoslab-regular-webfont.ttf') format('truetype'),
         url('./fonts/robotoslab-regular-webfont.svg#roboto_slabregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'roboto_slabbold';
    src: url('./fonts/robotoslab-bold-webfont.eot');
    src: url('./fonts/robotoslab-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/robotoslab-bold-webfont.woff') format('woff'),
         url('./fonts/robotoslab-bold-webfont.ttf') format('truetype'),
         url('./fonts/robotoslab-bold-webfont.svg#roboto_slabbold') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
	font-family: "Flaticon";
	src: url("../fonts/flaticon.eot");
	src: url("../fonts/flaticon.eot#iefix") format("embedded-opentype"),
	url("../fonts/flaticon.woff") format("woff"),
	url("../fonts/flaticon.ttf") format("truetype"),
	url("../fonts/flaticon.svg") format("svg");
	font-weight: normal;

	font-style: normal;
}


@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon.eot");
  src: url("../fonts/flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon.woff") format("woff"),
       url("../fonts/flaticon.ttf") format("truetype"),
       url("../fonts/flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
    font-family: 'latobold';
    src: url('./fonts/lato-bold-webfont.woff2') format('woff2'),
         url('./fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latolight';
    src: url('./fonts/lato-light-webfont.woff2') format('woff2'),
         url('./fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latoregular';
    src: url('./fonts/lato-regular-webfont.woff2') format('woff2'),
         url('./fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'latosemibold';
    src: url('./fonts/lato-semibold-webfont.woff2') format('woff2'),
         url('./fonts/lato-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sansbold';
    src: url('./fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('./fonts/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansextrabold';
    src: url('./fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('./fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanslight';
    src: url('./fonts/opensans-light-webfont.woff2') format('woff2'),
         url('./fonts/opensans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansregular';
    src: url('./fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('./fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanssemibold';
    src: url('./fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('./fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'montserratbold';
    src: url('./fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratextrabold';
    src: url('./fonts/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratlight';
    src: url('./fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratregular';
    src: url('./fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratsemibold';
    src: url('./fonts/montserrat-semibold-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
.ant-layout-sider{
     background:#404040 !important;
}