
    *{
    box-sizing:border-box;
    margin:0;
    padding:0;
    }
    .ant-layout-sider {
      min-width: 220px !important;
      background: var(--primarycolor) !important;
      height: 100vh;
  }
   .smta .logo2 {
      width: 220px;
      height: 65px;
      background-image:url(../../images/logo-mobile.png);
      background-repeat: no-repeat;
      padding: 24px !important;
      margin: 0px !important; 
      float: left;
      background-size: 188px;
      background-color: #fff;
      background-position: 13px 8px;
  }

  .smtf .logo2 {
    width: 220px;
    height: 65px;
    background-image:url(../../images/Tango-Fever-logo.png);
    background-repeat: no-repeat;
    padding: 24px !important;
    margin: 0px !important; 
    float: left;
    background-size: 188px;
    background-color: #fff;
    background-position: 13px 8px;
}

.logosmart {  
  background-repeat: no-repeat; 
  margin: 0px !important; 
  float: left;
  background-size: 188px;
  background-color: #fff;
  background-position: 13px 8px;
}

.logosmart img{
  height: 65px !important;
  background-size: 50%;
}


    .ds-header{
    background-color: #222328;
    padding:15px 20px;
    float:left;
    width:100%;
    }
    .ds-header img{
    max-width:200px;
    }
    .header-right {
    float: right;
    padding-top:20px;
    }
    .header-right a{
    font-size:14px;
    color:#fff;
    text-transform:uppercase;
    }
    .ds-container{
    width:100%;
    }

    .ds_left_side_fixed{
    width:12%;
    float:left;
    display: inline;
    overflow: hidden;
    }
    .ds_right_side_content{
    width:88%;
    float:right;
    display: inline;
    overflow: hidden;
    }
    .sidebar{
    height:100%;
    background: #2c3e50;
    padding: 0px;
    text-align:left;
    min-height:830px;
    }
    .sidebar a{
    display:block;
    font-size:18px;
    padding:15px;
    color:#fff;
    text-transform:uppercase;
    }
    .right-space{
    margin-left:20px;
    margin-top:3px;
    }
    .ds-content{
    background:#fff;

    margin: 15px 15px 15px 0px;
    }
    .ds-footer{
    clear:both;
    background-color:#222328;
    padding:22px 15px;
    width:100%;
    }
    .footer-center{
    text-align:center;
    }
    .footer-center a{
    padding:0px 5px;
    color:#fff;
    text-transform:uppercase;
    font-size:12px;
    font-family: montserratregular,sans-serif!important;
    } 
    .row:after {
    content: "";
    display: table;
    clear: both;
    }
    .loyalty-row:after {
      content: "";
      display: table;
      clear: both;
      }
    .menubar-collapse{
    display:none;
    }
    ul.breadcrumb {
    padding: 10px 0px;
    list-style: none;
    background-color: #fff;
    }  
    .sideright_menu {
    background: transparent;
    border: none;
    color: #fff;
    }
    ul.breadcrumb li {
    display: inline;
    font-size: 15px;
    } 
    ul.breadcrumb li+li:before {
    padding:0px 4px;
    color: #000;
    content: "/\00a0";
    }   
    ul.breadcrumb li a {
    color: #000;
    text-decoration: none;
    }
    ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: none;
    }
    .sideright_menu .ant-menu-item{margin-bottom:0px;
    }
    .sideright_menu .ant-menu-item > a {
      display: inline-block;
      color: rgba(255, 255, 255);
  }
  .ant-layout .sideright_menu .ant-menu-item:hover{border-left: 10px solid #6a8094 !important;
    background-color:#6a8094 !important;
    z-index: 9999;
    width: auto;
    color:#ffffff!important;
  }
    .sideright_menu .ant-menu-sub {
    color: #fff;
    background: transparent;
    }
    .sideright_menu .ant-menu-submenu-title{ 
    border-left: 10px solid transparent;
    background-color: transparent !important;
    z-index: 9999;
    }
    .ant-layout .sideright_menu .ant-menu-submenu-title:hover{   
      border-left: 10px solid  #6a8094 !important;
      background-color:#6a8094 !important;
    z-index: 9999;
    width:auto;
    color:#fff !important;
    }
    .sideright_menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
  }
  .sideright_menu .ant-menu-item::after{border-right:none!important;}
  .sideright_menu  .ant-menu-submenu-open .ant-menu-submenu-selected{display:block;}
    .sideright_menu .ant-menu-item-selected > a{color:#fff!important;}

@media only screen and (max-width:767px){
  .mt-50{margin-top:0px;}
  .filter_top_course{width: 100%;float:left;overflow:hidden;}
  .ant-layout-sider{min-width:auto!important;}

}
.ant-layout-sider {
  min-width: 220px !important;
  position: fixed;
  width: 220px !important;
  margin-top: 64px;
  z-index: 9999;
}
.dashboard-common {
   overflow: auto; 
  margin-top:110px;
  margin-left: 220px !important ;
}
.ant-collapse{ box-shadow:2px 2px 2px #8d8080;}
.filter_top{padding: 10px;
      background: rgb(255, 255, 255);
      margin-bottom: 10px;
      box-shadow: 2px 2px 2px #8d8080;
      border-radius: 6px;
      border-left: 6px solid #330e79;}
      .filter_top:first-child{  border-left: 6px solid #cb2a32;}
.dashboard-common .   {
  margin-top: 64px !important;
  position: fixed;
  width: 100%;
  z-index: 9999;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #b0b4b8;
  font-weight: bold;
}
.box-model {
  background: #fff;
  margin: 15px;margin-top: 130px;
  padding: 15px;
  border-left: 6px solid #c82734;
  border-radius: 10px;
  box-shadow: 5px 6px 1px #00000054;
} .dashboard-common .search_course_btn button {
  width: 15%;
  float: right;
 margin-left: 0px; 
  margin-bottom: 10px;
  height: 38px;
  background: #203864;
  border: 1px solid #203864;
}.dashboard-common .search_course_btn button:hover{opacity: 0.9;}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
  position: fixed;
  z-index: 9999;
}

.dasboard-box {
  background: #f0f2f5;
  width: 31%;
  float: left;
  box-shadow: 5px 4px 1px #00000038;
  border-radius: 10px;
  margin: 0px 10px 15px 10px;
}
  .dasboard-content {
    width: 88%;
    padding: 10px 15px 0px 15px;
    float: left;
}
.dasboard-content p {
  width: 100%;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 13px;
}
  .dasboard-content .plfno{text-align: right;}
  .dasboard-title {
    text-transform: uppercase;
    transform: rotate(180deg) translate(-4px);
    writing-mode: vertical-lr;
    text-align: center;
    margin-bottom: 0;
    color: #fff;
    font-size: 20px;
    background:#ff3a31;
    width: 100%;letter-spacing: 3px;
    line-height: 1;
    width: 12%;
    min-height: 195px;
    float: left;
    padding: 10px;
    font-weight: bold;
    margin-left: -5px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.dasboard-content p strong{    width: 50%;
  display: inline-block;font-weight: 700;
  text-transform: uppercase;}
.dasboard-content button{  background:#ff3a31;border:none;color:#fff;}
.dasboard-content .view-btn{text-align: center;
  margin-left: 56px;}

  .ant-dropdown{z-index: 9999 !important;}

  .dasboard-box.red .dasboard-title {  background:#ff3a31}
  .dasboard-box.violet .dasboard-title {  background:#742d69}
  .dasboard-box.blue .dasboard-title {  background:#0b1577}
  .dasboard-box.brown .dasboard-title {  background:#614c47}
  .dasboard-box.green .dasboard-title {  background:#0d7236}
  .dasboard-box.yellow .dasboard-title {  background:#c1c41f}
  .dasboard-box button:hover{opacity: 0.9;}
  .dasboard-box.red button{  background:#ff3a31;border:none;color:#fff;}
  .dasboard-box.violet button{  background:#742d69;border:none;color:#fff;}
  .dasboard-box.blue button{  background:#0b1577;border:none;color:#fff;}
  .dasboard-box.brown button{  background:#614c47;border:none;color:#fff;}
  .dasboard-box.green button{  background:#0d7236;border:none;color:#fff;}
  .dasboard-box.yellow button{  background:#c1c41f;border:none;color:#fff;}

  @media only screen and (max-width:767px){
    .dashboard-common {margin-left:0px !important;width:100% !important;}
    .dasboard-box{width:100% !important;margin:0px 10px 15px 0px;}
    .dashboard-common .search_course_btn button{width:50% !important;}
    .box-model{margin-top: 130px;}
     .ant-breadcrumb {
      margin-top: 63px!important;
      margin-left: 0px !important;
     }
     .ant-layout-header .logo2 {
      background-size: 65%;
      background-position: 20%;
  }
     .ant-layout-header .ant-badge{color:#Fff !important}
     .ant-layout-header .ant-dropdown-link > .anticon.anticon-down{color:#Fff !important}
    
    
     .ant-layout-header{background: var(--primarycolor) !important;}
     .ant-layout-content .ant-breadcrumb {
      margin: 64px 0px 0px 0px !important; z-index: 2 !important;}
      .ant-layout-header .logo2{background-color: var(--primarycolor) !important;}
      .profile_info_right{padding-left:0px !important;}
      .ant-time-picker{width:100% !important;}
      .manage_course_info input,  .manage_course_info select {
        max-width: 100%;
        height: auto;
        min-height: 32px;
        line-height: 1.5;
        vertical-align: bottom;
        transition: all 0.3s, height 0s;
        padding: 5px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
      }
      .ant-layout-content .dashboard_button {
        width: 100%;
      }
      .ant-layout-sider-zero-width-trigger{background: var(--primarycolor) !important;}
      .ant-layout .sideright_menu .ant-menu-item {
        margin: 0px !important;
        padding: 0px !important;
      }
      .ant-layout-content .top_header_filer_heading .filer_opt {
        width: 100%;
      }


  }




  @media only screen and (max-width:991px)  and (orientation:landscape) {

    .ant-layout .ant-layout-content .ant-breadcrumb {
      margin: 64px 0px 0px 0px !important; z-index: 2 !important;}
      .ant-layout .dashboard-common {
        margin-left: 0px !important;
        width: 100% !important;
      }
  }


  @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) 
  { 
    .ant-layout-header{background: var(--primarycolor) !important;}
  .ant-layout-content .ant-breadcrumb {
   margin: 64px 0px 0px 202px !important; z-index: 2 !important;}
   .ant-layout-header .logo2{background-color: var(--primarycolor) !important;}
   .profile_info_right{padding-left:0px !important;}
   .ant-time-picker{width:100% !important;}
   .manage_course_info input,  .manage_course_info select {
     max-width: 100%;
     height: auto;
     min-height: 32px;
     line-height: 1.5;
     vertical-align: bottom;
     transition: all 0.3s, height 0s;
     padding: 5px;
     border: 1px solid #d9d9d9;
     border-radius: 5px;
   }
  
   .ant-layout-sider-zero-width-trigger{background: var(--primarycolor) !important;}
   .ant-layout .sideright_menu .ant-menu-item {
     margin: 0px !important;
     padding: 0px !important;
   }
  
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .dasboard-page .ant-list-item-action {margin: 20px 20px 15px 0px;}

  .ant-layout-header{background: var(--primarycolor) !important;}
  .ant-layout-content .ant-breadcrumb {
   margin: 64px 0px 0px 0px !important; z-index: 2 !important;}
   .ant-layout-header .logo2{background-color: var(--primarycolor) !important;}
    .dashboard-common {margin-left:0px !important;width:100% !important;}
    .dasboard-box{width:47% !important;margin:0px 15px 15px 1px;}
    .dashboard-common .search_course_btn button{width:25% !important;}
    .box-model{margin-top: 130px;}
    .ant-breadcrumb {
      margin-top: 63px!important;
     }
     .ant-layout .sideright_menu .ant-menu-item {
      margin: 0px !important;
      padding: 0px !important;
    }
     .ant-layout-header .logo2 {
      background-size: 65%;
      background-position: 20%;
  }

  .profile_info_right{padding-left:0px !important;border:none !important;}
     .ant-layout-header .ant-badge{color:#Fff !important}
     .ant-layout-header .ant-dropdown-link > .anticon.anticon-down{color:#Fff !important}
     .ant-layout-header{background: var(--primarycolor) !important;}
     .ant-layout-header .user_details{color:#Fff !important}
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
    .dashboard-common {
      margin-left: 200px!important;
      width: 80% !important;
  }
    .dasboard-box{width:48% !important;margin:0px 12px 15px 2px;}
    .dashboard-common .search_course_btn button{width:25% !important;}
    .box-model{margin-top: 130px;}
    .ant-breadcrumb {
      margin-top: 63px!important;
     }
     .ant-layout-header .logo2 {
      background-size: 65%;
      background-position: 20%;
  }
     .ant-layout-header .ant-badge{color:#Fff !important}
     .ant-layout-header .ant-dropdown-link > .anticon.anticon-down{color:#Fff !important}
     .ant-layout-header{background: var(--primarycolor) !important;}
     .ant-layout-header .user_details{color:#Fff !important}
  }
  


  @media only screen and (min-device-width:320px) and (max-device-width:568px)and (-webkit-device-pixel-ratio:2) and (device-aspect-ratio:40/71) and (orientation:portrait)
  {
    .ant-btn .anticon{display:none;}
    .dasboard-content p{font-size: 12px;}
 .box-model{padding: 10px;
 border: none;
 margin: 130px 5px 5px 5px;}
}

.manage_course_info span{font-weight: 600;}
.ant-breadcrumb {
  margin: 64px 0px 0px 220px !important;
  background: #fff;
  padding: 10px;
  position: fixed;
  width: 100%;
  box-shadow: 0px 3px 2px #a79e9f;
  z-index: 9999;
  border-top: 1px solid #d7d7d7;
  font-weight: bold;
}
.ant-breadcrumb-link{font-weight: bold;color:var(--primarycolor);text-transform: capitalize;}


.ant-list-item-action a{border-radius: 20px;
  background: var(--primarycolor);
  padding: 10px 20px;
  color: #fff;
  box-shadow: inset 2px -1px 1px #000;}


 .ant-collapse-header {
    background: #a31616;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff !important;
}
  
  .dasboard-page .ant-list-item-action a:hover{opacity: 0.5;}

  .dasboard-page .ant-list-item-meta-description > div {
    color: #000;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
}
.dasboard-page .ant-list-item-meta-title {
  margin-bottom: 4px;
  color: var(--primarycolor);
  font-size: 17px;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: 600;
}

@media only screen and (max-width:767px){
  .dasboard-page .ant-list-item{width:100%;display: block;}
  .dasboard-page .ant-list-item-action {
    margin: 20px 20px 15px 0px;
}
.rightsettings a{color:#fff !important;margin-right: 10px !important;}

}
.ant-switch-checked {
  background-color: var(--primarycolor) !important;
  width:44px;
}
.dasboard-page .ant-collapse-header{
    background: #a31616;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff !important;
}
.checkoutbasket .ant-statistic-title {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  color: var(--primarycolor);
  text-transform: capitalize;
}
.checkoutbasket .ant-radio-wrapper {font-weight: bold;margin-bottom: 15px;}
.checkoutbasket .login-btn-style{width:auto;}
.checkoutbasket .ant-collapse-content-box form button {
  padding: 8px 30px;
  border-radius: 20px;
  box-shadow: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background: var(--primarycolor);
  color: #fff;
  margin-top: 13px;
}
.rightsettings a{font-size: 18px;
  margin-right: 20px;
  color: #000;
  font-weight: bold;
  margin-left: 15px;}

  .checkoutbasket .row-label label{text-transform: capitalize !important;color: #595959 !important;
    font-family: montserratlight,sans-serif !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    font-variant: tabular-nums;}

    .ant-layout .dashboard-common .checkoutbasket.logout .ant-list-item-action a{color:#fff !important;}
    .ant-layout .dashboard-common .checkoutbasket.logout .ant-page-header-heading-title{font-size: 18px;}
    .site-card-wrapper .ant-result-success{background:#fff;}
    .site-card-wrapper .ant-result-icon svg{width:80px;height:80px;}
    .site-card-wrapper .ant-result-subtitle{font-size: 20px;}
    .site-card-wrapper .ant-result-subtitle {
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }
    .site-card-wrapper .ant-page-header-heading-title {
      display: inline-block;
      padding-right: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      font-size: 18px;
      line-height: 1.4;
    }
    .site-card-wrapper .ant-statistic-title {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: bold;
      color: var(--primarycolor);
      text-transform: capitalize;
    }
  

   .dasboard-page h2 {color: var(--primarycolor);
      font-size: 17px !important;
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 13px;}
      .attendance-details{font-size: 13px !important;
        font-weight: bold;
        margin-bottom: 10px;}
        .btm-canreview {
          background: #c0c0c0;
          padding: 3px 32px;
          margin-right: 10px;
          border: none;
          font-weight: 600;
          font-size: 14px;
          color: #000 !important;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 10px !important;
        }
.btn-center{text-align: center;}