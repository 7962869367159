
    *{
    box-sizing:border-box;
    margin:0;
    padding:0;
    font-size: 15px;
    }
    @media only screen and (max-width:767px){
      .btm-save{margin-bottom: 10px;}
      .btm-review{margin-bottom: 10px;}
      .btm-next {margin-right:0px !important;}
      .document_info_right{width:100% !important;padding-right:0px !important;}
      .document_info_left{width:100% !important;}
      .profile_info_right{border:none !important;padding-left: 0px;}
    }
    .profile_info_right {
      width: 50%;
      float: left;
      overflow: hidden;
      border-left: 6px solid #d7d7d7;
      padding-left: 20px;
  }
    .manage_course_label_full{width:100%;font-weight: bold;}
    .box-img img {
      width: 100%;
      display: block;
      padding: 10px;
      border: 1px solid #d7d7d7;
      border-radius: 5px;
      margin: 10px 15px 10px 0px;
  }
  .report-generation .manage_course_label{width:15%;}
  .report-generation select{width:30%;}
    .box-img{width:25%;float: left;padding-right:15px;}
    .box-img a {
      display: inline-block;
      width: auto;
      text-align: center;
      color: #3c4a64;
      font-size: 13px;
      text-transform: capitalize;
      font-weight: bold;
      padding:5px 0px 0px 15px;
      text-decoration: underline;
  }
    .heading-title{    font-weight: bold;
      font-size: 16px;
      margin-bottom: 0px;
      margin-top: 5px;}
      .btm-save:hover {
        opacity: 0.9;
        background: #5b4dde;
    }
    .profile_info_right .ant-card-bordered{    background: #ffefa7;
      border: 1px solid #cdb754;}

    .btm-review:hover{opacity: 0.9;
      background: #f27473;}
    .btm-save {background: #321fdb;
      padding: 3px 32px;
      margin-right: 10px;
      border: none;
      font-weight: 600;
      font-size: 14px;}
      .profile_info_right{margin-bottom: 10px;}
       .document_info_right{margin-bottom: 10px;}
       .profile_info_right .ant-card-body {
        padding: 15px 15px;font-weight: 600;
        zoom: 1;
    }
       .document_info_right .ant-card-head-title{padding:5px 0px;}
      .document_info_right .ant-card-body {
        padding: 0px 24px 15px;font-weight: 600;
        zoom: 1;
    }
      .document_info_right .ant-card-bordered{    background: #ffefa7;
        border: 1px solid #cdb754;}
        .document_info_right .ant-card-head {    border: none;
          min-height: 30px;
          font-weight: bold;
          text-transform: uppercase;}
      .btm-next:hover{opacity: 0.9;
        background: #46c836;}
      .btm-next {background: #29891d;
        padding: 3px 32px;
        margin-right: 20px;
        border: none;
        font-weight: 600;
        font-size: 14px;}
        .filter_top .profile_info_right{
          padding-top: 30px !important;
    margin-top: 0px !important;
         
              margin-bottom: 0px;}
        .dashboard_button{text-align: left;margin-bottom: 15px;}
    .box-model-addtest{margin:10px;}
    .btm-review{background: #dc3e3d;
      padding: 3px 32px;
      margin-right: 10px;
      border: none;
      font-weight: 600;
      font-size: 14px;}
      .btm-dashboard:hover{opacity: 0.9;
        background: #153674;}
      .btm-dashboard{background: #0e2755;
        padding: 3px 32px;
        margin-right: 10px;
        border: none;
        font-weight: 600;
        font-size: 14px;}
        .ant-tag {
          font-size: 16px;
          padding: 3px 10px 3px 10px;
      }
    .box-model-addtest{margin:10px;}
    .ant-collapse-header{background:#d7d7d7;font-weight: bold;text-transform: capitalize;}
    .ds-header{
    background-color: #222328;
    padding:15px 20px;
    float:left;
    width:100%;
    }
    .ds-header img{
    max-width:200px;
    }
    .header-right {
    float: right;
    padding-top:20px;
    }
    .header-right a{
    font-size:14px;
    color:#fff;
    text-transform:uppercase;
    }
    .ds-container{
    width:100%;
    }
    .ds_left_side_fixed{
    width:12%;
    float:left;
    display: inline;
    overflow: hidden;
    }
    .ds_right_side_content{
    width:88%;
    float:right;
    display: inline;
    overflow: hidden;
    }
    .sidebar{
    height:100%;
    background: #2c3e50;
    padding: 0px;
    text-align:left;
    min-height:830px;
    }
    .sidebar a{
    display:block;
    font-size:18px;
    padding:15px;
    color:#fff;
    text-transform:uppercase;
    }
    .right-space{
    margin-left:20px;
    margin-top:3px;
    }
    .ds-content{
    background:#fff;

    margin: 15px 15px 15px 0px;
    }
    .ds-footer{
    clear:both;
    background-color:#222328;
    padding:22px 15px;
    width:100%;
    }
    .footer-center{
    text-align:center;
    }
    .footer-center a{
    padding:0px 5px;
    color:#fff;
    text-transform:uppercase;
    font-size:12px;
    font-family: montserratregular,sans-serif!important;
    } 
    .row:after {
    content: "";
    display: table;
    clear: both;
    }
    .loyalty-row:after {
      content: "";
      display: table;
      clear: both;
      }
    .menubar-collapse{
    display:none;
    }
    ul.breadcrumb {
    padding: 10px 0px;
    list-style: none;
    background-color: #fff;
    }  
    .sideright_menu {
    background: transparent;
    border: none;
    color: #fff;
    }
    ul.breadcrumb li {
    display: inline;
    font-size: 15px;
    } 
    ul.breadcrumb li+li:before {
    padding:0px 4px;
    color: #000;
    content: "/\00a0";
    }   
    ul.breadcrumb li a {
    color: #000;
    text-decoration: none;
    }
    ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: none;
    }
    .sideright_menu .ant-menu-item{margin-bottom:0px;
    }
    .sideright_menu .ant-menu-item > a {
      display: inline-block;
      color: rgba(255, 255, 255);
  }
  .sideright_menu .ant-menu-item:hover{border-left: 10px solid #beca0f;
    background-color:#ff5012 !important;
    z-index: 9999;
    width: auto;
    color:#ffffff!important;
  }
    .sideright_menu .ant-menu-sub {
    color: #fff;
    background: transparent;
    }
    .sideright_menu .ant-menu-submenu-title{ 
    border-left: 10px solid transparent;
    background-color: transparent !important;
    z-index: 9999;
    }
    .sideright_menu .ant-menu-submenu-title:hover{   
      border-left: 10px solid #beca0f;
      background-color:#ff5012 !important;
    z-index: 9999;
    width:auto;
    color:#fff !important;
    }
    .sideright_menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
  }
  .sideright_menu .ant-menu-item::after{border-right:none!important;}
  .sideright_menu  .ant-menu-submenu-open .ant-menu-submenu-selected{display:block;}
    .sideright_menu .ant-menu-item-selected > a{color:#fff!important;}
    .filter_top{width: 100%;display: inline-block;}
    .filer_opt {
      width: 16%;
      float: left;
      display: inline-grid;
      margin-right: 10px;
      overflow: hidden;
  }
  .ant-layout-sider {
    min-width: 220px!important;
}
  .filer_opt_name{ width: 100%;
    float: left;
    margin-right:10px;
    overflow: hidden;
    margin-top:5px;margin-bottom:25px;font-size:16px;}
  .filer_opt_label{
    width: 16%;
    float: left;
    margin-right:10px;
    overflow: hidden;
    font-weight: bold;
    margin-top:5px;
    text-align: left;
  }
  .filer_label{width: 16%;
    float: left;font-weight: bold;margin-top:3px;}
  .filer_opt_box{ width: 37%;
    float: left;
    display: inline-grid;
    margin-right: 10px;
    overflow: hidden;}
    .filer_opt_count{ width: 30%;
      float: left;
      display: inline-grid;
      margin-right: 10px;
      overflow: hidden;}
    .filer_opt_count span{font-weight: bold;}
  .ant-btn-primary {height:32px;}

  /* .sideright_menu .ant-menu-item{padding-left:25px!important;} */

  .filer_opt_2 {
    width: 48%;
    float: left;
    display: inline-grid;
    margin-right: 7px;
    overflow: hidden;
    margin-bottom:5px;
}


  .filer_opt span{ font-size:13px;color: rgba(0,0,0,0.85);
    font-family: montserratregular,sans-serif!important;font-weight:600;
  }
  .filer_opt_2 h3{font-size:15px;font-family: montserratregular,sans-serif!important;font-weight:600;}

  .filer_opt button span{color:#fff!important;font-weight:500!important;}
  .loyalty .medium-image {
    text-align: center;
    text-align: -moz-center !important;
 text-align: -webkit-center !important;
 }
 .loyalty .medium-image img{
     text-align: center;
     text-align: -moz-center !important;
  text-align: -webkit-center !important;
  }
  
  .no_img {
     width: 110px;
     height: 110px;
 }
 .loyalty .revarts {
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "montserratsemibold",sans-serif;
  font-size: 22px;
  letter-spacing: 2px;
  font-weight: 100;
}
.loyalty .medium-txt-center {
  padding-top: 41%;
  text-align: center;
  font-size: 15px;
  color: #b5b5b5;
  font-family: "montserratlight",sans-serif !important;
}
.loyalty .credit-amount {
  font-size: 15px;
  color: #b5b5b5;
  font-family: "montserratlight",sans-serif !important;
}
.loyalty .content-section {
  border-top: none;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}
.loyalty ul.newthemeca_classbox li {
  width: 133px;
  margin-right: 30px;
  padding-bottom: 25px;
  min-height: 200px;
}
 .content-section  {
  font-size: 11px !important;
  text-align: center;
  text-transform: uppercase;
  color: #3498db !important;
  font-family: "montserratlight",sans-serif !important;
  word-wrap: break-word;
  text-decoration:none;
  background:none;
}
.loyalty .content-section button {
  font-size: 11px !important;
  text-align: center;
  text-transform: uppercase;
  color: #3498db !important;
  font-family: "montserratlight",sans-serif !important;
  word-wrap: break-word;
  text-decoration:none;
  text-transform: capitalize;
  background:none;
  border:none;
  outline:none;
}
ul.newthemeca_classbox li {
  list-style: none;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 0;
}
ul.newthemeca_classbox li {
  position: relative;
}
.loyalty .bck-images {

  background-image: url(../../images/stamp-circle.png);
  background-position: 100%;
  background-size: 100%;
  width: 110px;
  height: 110px;
}
.loyalty .bck-images-golden {
  background-image: url(../../images/golden.png);
  background-position: 100%;
  background-size: 100%;
  width: 110px;
  height: 110px;
}
.loyalty .revarts {
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "montserratsemibold",sans-serif;
  font-size: 22px;
  letter-spacing: 2px;
  font-weight: 100;
}

  .card-type{width:45px;height:34px;background:url(../../images/allcards.jpg);background-repeat:no-repeat;margin:0 auto;}
  .card-type.visa{background-position:0 -66px;}
  .card-type.amex, .card-type.American.Express{background-position:0 -32px;}
  .card-type.mastercard{background-position:0 2px;margin-top:2px;}
  .card-type.paypal{background-position:0 -133px;}
  .card-type.discover{background-position:0 -100px;}
  .filter_top .email{width:100%;border:1px solid #d2d1d1;border-radius: 5px;height:35px;padding-left: 10px;}

  .ant-avatar-circle{margin-left:35px;margin-right: 25px;}
  .ant-dropdown-link{font-size: 12px;
    font-family: "montserratregular",sans-serif !important;
    letter-spacing: 1px !important;
    color: #222328 !important;font-weight: 100;}
   
  .sideright_menu .ant-menu-submenu-open:active .ant-menu-submenu-title{background:#3474a6;}
  .ant-menu-item{border-left:10px solid transparent;}
  .sideright_menu .ant-menu-item {
        margin: 0px !important;}
  .sideright_menu  .ant-menu-submenu .ant-menu-submenu-title{background-image: url(../../images/leftmenuright.png);
        background-repeat: no-repeat;
        background-position-x: right;  
        background-position-y: 0px;}
  .sideright_menu .ant-menu-submenu-open .ant-menu-submenu-title{background-image: url(../../images/leftmenudown.png);
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: 5px;}
.rightsettings{text-align: right;}
.responsive_table,.no_resp { 
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
    }
  
 
  .responsive_table tr:nth-of-type(odd),.no_resp tr:nth-of-type(odd) { 
    background: #fff; 
    }
  
  .responsive_table th,.no_resp th{ 
    background: #fafafa; 
    color: rgba(0,0,0,0.85); 
    font-weight: 600;
    font-family: "montserratregular",sans-serif !important;
font-size: 15px;
text-transform: capitalize;

    }
   .responsive_table td,.no_resp td{ 
          
      font-weight: 100;
      font-family: "montserratlight",sans-serif !important;
      font-size: 13px;
      }
  
      .responsive_table td, .responsive_table th ,.no_resp td,.no_resp th{ 
    padding: 15px 5px; 
    border-bottom: 1px solid #e8e8e8; 
    text-align: center; 
   word-break: break-word;
    }
    .ant-checkbox-inner::after {left:22%!important;}
    


    .ant-scroll-number{left:0px !important;min-width: 25px !important;}
    .ant-menu-item > a{color:#fff;display: inline-block;}
  @media 
  only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px)  {
    .ant-layout-sider {
      min-width: auto!important;
  }
  .filer_opt_name{ margin-bottom:15px;}
    .filer_opt_label{

      text-align: left;
    }
    .filer_label{width: 65%;}
    .filer_opt_count{width:100%;margin-bottom: 10px;}
    .filer_opt_name{ width: 100%;}
    .rightsettings{padding-right:15px;}
    .responsive_table td:nth-of-type(odd) {
      background: #f5f5f5;
  }
    .filer_opt_box{ width: 100%;margin-bottom: 10px;}
    .responsive_table { 
        width: 100%; 
    }
  
  
    .responsive_table, .responsive_table thead, .responsive_table tbody, .responsive_table th, .responsive_table td, .responsive_table tr { 
      display: block; 
    }
    
  
    .responsive_table thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    
    .responsive_table tr { border:1px solid #ccc;margin-bottom: 15px;box-shadow: 1px 1px 2px 2px #c9c9c9;border-radius:4px;}
    
    .responsive_table td { 
  
      border: none;
      border-bottom: 1px solid #ccc;
      position: relative;
      word-wrap: break-word;
      padding-left: 52%; 
      text-align:left;
      
    }
  
    .responsive_table td:before { 
    
      position: absolute;
    
      top: 13px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
      content: attr(data-column);
      color: #000;
      font-weight: bold;

    }
    
    .filer_opt_label{width:100%;margin-bottom: 5px;}
    .filter_top_course {
      width: 100%!important;
      float: left;
      overflow: hidden;
  }
  
  }
@media (max-width:320px) and (max-width:568px) and (orientation:portrait){
.logo2{background-size: 50%;background-position: 0%;}
}
@media only screen and (max-width: 767px) and (orientation:portrait){

  .filer_opt {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
}
.filer_opt td{display: none;}
.empty{display: none;}
.rightsettings{width:65% !important;}
.logo2{background-size: 65%;background-position: 0%;}
.leftsettings{width:35% !important;}
.ant-dropdown-link .user_details{display: none;}

.filer_opt_2 {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0px;
}
}
@media only screen and (max-width: 767px) and (orientation:landscape){
  .ant-dropdown-link .user_details{display: none;}
  .logo2{background-size: 80%;background-position: 0%;}
  .filer_opt {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
}
.filer_opt_2 {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0px;
}
.empty{display: none;}
.filter_top_course {
  width: 100%;
  float: left;
  overflow: hidden;
}
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation:portrait) {
  .filer_opt {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
}
.filer_opt_2 {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0px;
}
.filer_opt button{margin:0px;}
.empty{display: none;}
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
  .empty{display: block;}
  .filer_opt{margin-right: 4px;}

}

@media only screen and (max-width:767px){

  .loyalty .bck-images {
      width: 85px !important;
      height: 85px !important;
  }
 
  .loyalty .bck-images-golden {
      width: 85px !important;
      height: 85px !important;
  }
  .loyalty .credit-amount {
      font-size: 13px;
      color: #b5b5b5;
      font-family: "montserratlight",sans-serif !important;
  }
  
  .no_img {
      width: 85px;
      height: 85px;
  }
  .filter_top_course{width: 100%;float:left;overflow:hidden;}

}
.card-container.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {border:none!important;background:#fff;}
.card-container.ant-tabs-card .ant-tabs-bar {border:none!important;}


.search_drawer .ant-drawer-content-wrapper{width:300px!important;}
 .profile_info_left{width: 50%;float:left;overflow:hidden;}
    .profile_info_right{width: 50%;float:left;overflow:hidden;}
    .com-ilatic {
      font-style: italic;
      font-weight: bold;
      text-align: right;
      margin-bottom: 0px;
      color: #000;
  }
  .com-ilatic-text {
    font-style: italic;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0px;
    color: #000;
    margin-bottom: 0px;
}
.com-ilatic-left{
    text-align: left;
    margin-bottom: 0px;

}
.document_info_right .red-lable.ant-card-bordered {
  background: #ffcfcf;
  border: 1px solid #dc3e3d;
  margin-top: 10px;
  
  padding: 10px;
}
.document_info_left .yellow-lable.ant-card-bordered .btm-next{
float: right;    background: #2f14a7;border-radius: 10px;
margin: 0px;margin-bottom: 10px;}



.document_info_right .red-lable.ant-card-bordered .ant-card-body{padding:0px}
.document_info_left .yellow-lable.ant-card-bordered .ant-card-body{padding:0px}
.document_info_left .yellow-lable.ant-card-bordered {
  background: #ffefa7;
  margin-right:15px;
  border: 1px solid #cdb754;
  margin-top: 10px;
  padding: 10px;
}
  .save_button_center{text-align: center;}
    .box-model-addtest .document_info_left{width: 50%;float:left;overflow:hidden;}
    .box-model-addtest .document_info_right{width: 50%;float:left;overflow:hidden;padding-right: 20px;}
	.manage_course_label{
  width: 30%;
  float: left;
  margin-right: 7px;
  overflow: hidden;
  font-weight:bold;
  /* text-align: right; */
}

.manage_course_info{
  width: 65%;
  float: left;
  display: inline-grid;
  margin-right: 7px;
  overflow: hidden;}
  
  .row{
	  padding-bottom: 10px;
  }
  
  .email {
    width: 100%;
    border: 1px solid #d2d1d1;
    border-radius: 5px;
    height: 35px;
    padding-left: 10px;
}
.selectopt{
	 width: 100%;
    border: 1px solid #d2d1d1;
    border-radius: 5px;
    height: 35px;
    padding-left: 10px;
}
.save_button{
	text-align:right;
}

.btm_svbtn{
	margin:15px;
}
.cloaps_btn{
	margin: 10px;
}

/* .card-container > .ant-tabs-card > .ant-tabs-content {
    height: 100% !important;
    margin-top: -16px;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #f6f6f6;
    padding: 16px;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #f6f6f6;
  } */
  .labletitlebold{
    font-weight: bold;
  }
  /* .card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  
  .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  } */
 

  @media only screen and (max-width:767px), (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){

    .loyalty ul.newthemeca_classbox.classlisting_bottom_space li {
        padding-top: 0;
        width: 33% !important;
        min-height: 158px !important;
        margin-right: 0;
        text-align: center;
        padding-bottom: 0;
    }
    
    .loyalty ul.newthemeca_classbox li:nth-child(3n+1) {
        clear: none;
    }
    .card-type{margin:0;}
   
}

.email_preferences{width:100%;}
.check_box .check input{margin-left: 10px;
  top: 3px;
  position: relative;
left:7px;}
.check_box .check_no input{margin-left: 10px;
  top: 3px;
  position: relative;
left:10px;}
.icont span.content-particular{padding-left:5px;}
.icont img{padding-left:5px;}
.stc-midletext{padding-left:5px;}
.accordition textarea{width:100%;}


@media only screen and (max-width:767px), (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){
  .ant-layout-sider{min-width:auto!important;}
  .filter_top_course{width: 100%;float:left;overflow:hidden;}
  .loyalty ul.newthemeca_classbox.classlisting_bottom_space li {
      padding-top: 0;
      width: 33% !important;
      min-height: 158px !important;
      margin-right: 0;
      text-align: center;
      padding-bottom: 0;
  }
  
  .loyalty ul.newthemeca_classbox li:nth-child(3n+1) {
      clear: none;
  }
  .card-type{margin:0;}
  
   .profile_info_left {
    width: 100%;
    float: left;
    overflow: hidden;
}
	
	.profile_info_right {
    width: 100%;
    float: left;
    overflow: hidden;
}

 
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation:landscape) {
  .filter_top_course{width: 100%;float:left;overflow:hidden;}
  .empty{display: block;}
  .filer_opt{margin-right: 4px;}
  .ant-layout-sider{min-width:auto!important;}
.search_drawer .ant-drawer-content-wrapper{width:300px!important;}
    .profile_info_left{width: 50%;float:left;overflow:hidden;}
    .profile_info_right{width: 50%;float:left;overflow:hidden;}
	
	
	.manage_course_label{
  width: 30% !important;
  float: left;
  margin-right: 7px;
  overflow: hidden;
  font-weight:bold;
  /* text-align: right; */
}

.manage_course_info{
  width: 65% !important;
  float: left;
  display: inline-grid;
  margin-right: 7px;
  overflow: hidden;}
  
  .row{
	  padding-bottom: 10px;
  }
  
  .email {
    width: 100%;
    border: 1px solid #d2d1d1;
    border-radius: 5px;
    height: 35px;
    padding-left: 10px;
}
.selectopt{
	 width: 100%;
    border: 1px solid #d2d1d1;
    border-radius: 5px;
    height: 35px;
    padding-left: 10px;
}
.save_button{
	text-align:right;
}


}


@media only screen and (max-width:767px){
  .mt-50{margin-top:0px;}
  .filter_top_course{width: 100%;float:left;overflow:hidden;}
  .ant-layout-sider{min-width:auto!important;}
  .loyalty .bck-images {
      width: 85px !important;
      height: 85px !important;
  }
 
  .loyalty .bck-images-golden {
      width: 85px !important;
      height: 85px !important;
  }
  .loyalty .credit-amount {
      font-size: 13px;
      color: #b5b5b5;
      font-family: "montserratlight",sans-serif !important;
  }
  
  .no_img {
      width: 85px;
      height: 85px;
  }
  
  .search_drawer .ant-drawer-content-wrapper{width:300px!important;}
     .profile_info_left {
    width: 100% !important;
    float: left;
    overflow: hidden;
}
	
	.profile_info_right {
    width: 100% !important;
    float: left;
    overflow: hidden;
}

.manage_code_info, .manage_course_info, .manage_course_label, .manage_template_code, .manage_tutor_code{
	width: 100%;
	
}

}
.ant-modal{z-index: 1;}
.ant-modal-mask{z-index: 9999;}
.ant-modal-wrap{z-index: 9999;}
.ant-modal-wrap img{width:100%;}
.ant-layout{
  background-color: #f3f3f3 !important;
}

.succesmessage{
  color:green;
}

.ant-message{
  margin-top: 100px;
}